import logo from './logo.svg';
//import './App.css';
import LandingPage from "./LandingPage";
import UserMgmt from "./components/explore/UserMgmt"
//import Er from "./components/explore/Er"
//import Example from './components/Example';
///import Example from './components/DragFlow';


const  App = () => {
  return (
      
/*     <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */
    <LandingPage />
    
  );
}

export default App;
